@media(min-width: 1024px) {
    .modalAddress {
        position: relative;
        /* position: relative; */
        background-color: #fefefe;
        z-index: 9999;
        border: 1px solid #888;
        width: 30%;
        top: 50%;
        /* bottom: 50%; */
        left: 50%;
        transform: translate(-50%, -50%);
        /* -webkit-transform-origin-y: top; */
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
        animation-name: animatetop;
        animation-duration: 0.4s;
        padding: 20px;
    }

    .overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        transition: opacity 200ms;
        z-index: 9999;
    }
}


@media(max-width: 768px) {
    .modalAddress {
        position: relative;
        /* position: relative; */
        background-color: #fefefe;
        z-index: 99999;
        border: 1px solid #888;
        width: 80%;
        top: 50%;
        /* bottom: 50%; */
        left: 50%;
        transform: translate(-50%, -50%);
        /* -webkit-transform-origin-y: top; */
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
        animation-name: animatetop;
        animation-duration: 0.4s;
        padding: 20px;
    }

    .overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        transition: opacity 200ms;
        z-index: 9999;
    }
}



